.p-header {
    .p-header-logo {
        margin-left: 50px;
        margin-top: 50px;
        float: left;
    }

    @media (min-width: $screen-lg) {
        height: 200px;
    }
}

.p-btn-fill {
    color: $white;
    border-color: $white;
}

.p-btn-fill:hover {
    color: $blue;
    border-color: $white;
    background-color: $white;
}

#p-logo_img {
    margin-top: 27px;
    margin-bottom: 20px;
}

.p-navbar {
    background-color: transparent;
    border-color: transparent;

    .navbar-nav a, .navbar-nav a:hover, .navbar-nav a:focus {
        background-color: transparent;
        color: $black;

        @media (min-width: $screen-lg) {
            color: $white;
        }
    }

    .footer {
        display: none;
    }
}

.p-intro {
    height: 535px;
    position: relative;
    overflow: hidden;
}

.p-appstore {
    display: inline-block;
    width: 30%;
}

.p-errors {
    color: $red;
}

.p-demo-appstore {
    display: inline-block;
    width: 30%;
}

.p-padding-md {
    padding: 20px;
}

.p-wrapper {
    position: relative;
    max-width: 900px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.p-bold-title {
    float: left;
    width: 40%;
    font-family: $font-bold;
    text-align: right;
    margin-right: 5%;
}

.p-intro-bold-title {
    position: absolute;
    text-align: right;
    width: 40%;
    margin-left: -14%;
    margin-top: 15%;
}

.p-intro-header-content {
    position: absolute;
    float: left;
    width: 66%;
    left: 30%;
    margin-top: 15%;
}

.p-header-content {
    float: left;
    width: 55%;
}

.p-padding-lg {
    padding: 200px;
}

.p-padding-sm {
    padding: 50px;
    padding-left: 200px;
    padding-right: 200px;
}

.p-light {
    background-color: $white;
}

.p-gradient {
    background: #00B6C6;
    background: linear-gradient(to right, #00B6C6, #00A2D1);
    color: $white;
}

.p-screens {
    margin-left: 7%;
    margin-top: 28%;
    width: 229px;
    height: 250px;
}

.p-circle-screen {
    width: 300px;
    height: 300px;
    overflow: hidden;
}

.p-icon-bar {
    background-color: #333;
}

.p-wrapper-footer {
    .p-footer-nav {
        li {
            list-style: none;
            float: left;
            margin-left: 10px;
        }
    }
}

.p-questions-text {
    h1 {
        margin-top: 0px;
    }
}

p {
    font-size: 1.3em;
    line-height: 1.8em;
}

.p-contact-form .form-control {
    display: block;
    width: 100%;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.428571429;
    color: $dark-grey;
    background-color: white;
    background-image: none;
    border: 1px solid $light-grey;
    border-radius: 0px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.p-layer {
    background-color: rgba(248, 247, 216, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
