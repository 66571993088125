button {
    background-color: transparent;
}

.bootstrap-select {
    .btn-default {
        border: none;
    }
}

.p-history-content {
    .p-btn {
        width: 100px;
    }
}

.p-format-width {
    width: 104px !important;
}

.p-btn,
html body .fc .fc-button {
    border-radius: 1.25em;
    border: solid 2px;
    display: inline-block;
    padding-left: 0.625em;
    padding-right: 0.625em;
    padding-top: 0px;
    padding-bottom: 0px;
    min-height: 2.5em;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &[disabled],
    &[disabled]:hover {
        cursor: not-allowed;
        background-color: $light-grey;
        border-color: $light-grey;
        color: white;
    }

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }
}

.p-btn-sm {
    display: inline-block;
    border: 1px solid $blue;
    border-radius: 100%;
    background-size: 50%;
    background-position: center;
    width: 30px;
    height: 30px;
    margin-right: 10px;

    .p-icon {
        margin-top: 0.8em;
        font-size: .8em;
        width: 100%;
        text-align: center;
        float: left;
    }

    span {
        float: right;
        margin-top: 0.6em;
    }
}

.p-stretch {
    overflow: hidden;
    list-style: none;
    white-space: nowrap;
    margin-right: 10px;
    border-radius: 25px;
    display: inline-block;
    text-decoration: none;
    min-width: 0;
    transition: min-width .5s ease-out .1s, opacity .5s ease-out .1s, color;
}

.p-stretch:hover {
    width: 120px;
    transition: width .5s ease-out .1s, opacity .5s ease-out .1s, color;
}

.p-stretch .p-btn-text-wrapper {
    margin-left: 0px;
    opacity: 0;
    max-width: 0;
    margin-top: 0.7em;
    display: inline-block;
    text-decoration: none;
    transition: max-width .5s ease-out .1s, opacity .5s ease-out .1s, color;
}

.p-stretch .p-icon {
    line-height: 1;
    display: inline-block;
    padding: .125em .375em;
}

.p-stretch:hover div {
    margin-left: 10px;
    opacity: 1;
    text-align: center;
    color: $white;
    max-width: 100%;
    transition: max-width .5s ease-out .1s, opacity .5s ease-out .1s, color .2s;
}

.p-btn-block {
    display: block;
    width: 100%;
    text-align: center;

    .p-btn-text-wrapper {
        float: none;
    }
}

.p-btn-words {
    min-width: 110px;
    padding-left: 30px;
    padding-right: 30px;

    .p-btn-text-wrapper {
        float: none;
    }
}

.p-btn-words-slim {
    padding-left: 15px;
    padding-right: 15px;
}

.p-btn-md {
    min-width: 90px;
    padding-left: 20px;
    padding-right: 20px;

    .p-btn-text-wrapper {
        float: none;
    }
}

.p-btn-text-wrapper {
    float: left;
    padding: 5px 0;
}

.p-btn-text-wrapper-with-icon {
    padding: 5px 0;
    float: left;
    margin-left: 10px;
}

.p-btn-transparent {
    border-color: transparent;

    &:hover {
        background-color: transparent;
    }
}

$btn-colors: (
    blue: $blue,
    green: $green,
    grey: $light-grey,
    red: $red,
    developer: $purple,
    black: #000,
    white: #fff,
);

@each $name, $color in $btn-colors {
    .p-btn-#{$name} {
        color: $color;
        border-color: $color;
        background-color: transparent;

        &.p-btn-filled,
        &:hover,
        &:focus {
            color: $white;
            background-color: $color;
        }

        &.p-btn-filled {
            color: $white;
            background-color: $color;

            &:hover,
            &:focus {
                background-color: lighten($color, 6%);
            }
        }
    }
}

html body .fc .fc-button {
    color: $blue;
    border-color: $blue;

    &:hover {
        background-color: $blue;
        color: $white;
    }
}

.p-btn-icon {
    .p-icon {
        line-height: 35px;
        display: block;
    }

    .p-btn-text-wrapper-with-icon {
        display: none;
    }
}

.input-group-btn {
    .btn-default {
        height: 50px;
        border: 1px solid #aaa;
        color: #555;
        padding-left: 30px;
        padding-right: 30px;

        &:hover {
            border: 1px solid #999;
        }
    }
}

.p-body-dark {
    .input-group-btn {
        .btn-default {
            border: 1px solid #fff;
            color: #fff;
            background: transparent;

            &:disabled {
                color: #555;
                border: 1px solid #555;
                background: transparent;
            }
        }
    }
}

.p-btn-bulk-upload-files {
    display: block;
    margin: 0 0 15px;
    border-radius: 0;
    padding: 30px;
    background-color: $cloud;
    text-align: center;

    &:hover {
        background-color: lighten($cloud, 1%);
    }

    &,
    > div {
        width: 100%;
    }
}

.fc-button {
    background: transparent !important;
    padding: 0 30px !important;
    height: 37px;
    box-shadow: none !important;
    text-shadow: none !important;
    &:hover {
        background-color: $blue !important;
    }
}

.fc-prev-button {
    width: 35px;
    padding: 0 !important;
}

.fc-next-button {
    width: 35px;
    padding: 0 !important;
}

.p-btn-set {
    display: inline-block;

    .p-btn-set-icon {
        border-radius: 35px;
        height: 35px;
    }

    .p-btn-set-text {
        border: 0;
        padding: 0;
        margin: 0;
    }

    .dropup {
        float: left;
    }

    .p-dropdown-photo {
        max-width: 150px;
    }

    .dropdown-menu {
        padding: 0;
    }

    .p-photo-select {
        padding: 10px;
        cursor: pointer;
        font-weight: bold;
    }

    .p-photo-select-even {
        background-color: #eaeaea;
        border-bottom: 1px solid #eee;
    }
}
