// Spacing
$margin: 10px;
$padding: 10px;

// Colors
$black: #111111;
$green: #7D953F;
$blue: #00B6C6;
$dark-blue: #007DC5;
$red: #CF3337;
$dark-red: #a22023;
$darker-red: #4E0F10;
$orange: #F5A623;
$purple: #7F3BBB;
$yellow: #F1C40F;
$white: #FFFFFF;
$light-grey: #C8C8C8;
$lighter-grey: #9a9a9a;
$cloud: #F5F5F5;
$sapphire: #EEEEEE;
$grey: #31333B;
$btn-grey: #999999;
$dark-grey: #222327;
$select-grey: #303135;
$bg-grey: #f4f7fc;

$font-light: Helvetica, Arial, sans-serif;
$font-regular: Helvetica, Arial, sans-serif;
$font-bold: Helvetica, Arial, sans-serif;
$font-baloo: 'Baloo Bhai', Helvetica, Arial, sans-serif;
$font-barlow: 'Barlow', Helvetica, Arial, sans-serif;

$input-height: 50px;

$iconBaseUrl: "/images/icons/" !default;
$icons: (
    add: add,
    arrow: arrow,
    arrow-back: arrow-back,
    arrow-down: arrow-down,
    download: arrow-down,
    arrow-down-active: arrow-down-active,
    dropdown: arrow-down-active,
    arrow-left: arrow-left,
    upload: arrow-up,
    arrow-up: arrow-up,
    arrow-up-active: arrow-up-active,
    camera: camera,
    checked-in: checked-in,
    checked-out: checked-out,
    crop: crop,
    cross: cross,
    exclamation: exclamation,
    export: export,
    facebook: facebook,
    file: file,
    instagram: instagram,
    link: link,
    next: next,
    open: open,
    preview: preview,
    print: print,
    profile: profile,
    profile-paper: profile-paper,
    proof: proof,
    rotate: rotate,
    share: share,
    tag: tag,
    tick: tick,
    twitter: twitter,
    view: view,
    zoom: zoom,
    pencil: pencil,
);

$colors: (
    black: $black,
    green: $green,
    blue: $blue,
    dark-blue: $dark-blue,
    red: $red,
    orange: $orange,
    purple: $purple,
    yellow: $yellow,
    white: $white,
    light-grey: $light-grey,
    lighter-grey: $lighter-grey,
    cloud: $cloud,
    sapphire: $sapphire,
    grey: $btn-grey,
    dark-grey: $dark-grey,
    select-grey: $select-grey,
);

$iconColors: (
    green: #7D953F,
    blue: #00B6C6,
    developer: #7F3BBB,
    red: #CF3337,
    white: #fff,
    light: rgba(128, 128, 128, 0.25),
    grey: #31333B,
);

$sidebar-link: #97989c;
$sidebar-link-active: $white;
$sidebar-background-color: $grey;
$sidebar-background-hover-color: rgba(255, 255, 255, 0.09);

$body-background-color: $dark-grey;

// Sizes
$sidebar-width: 15%;

// Depth
$z-sidebar: 100;
$z-visor: 110;
$z-editor: 120;
$z-breadcrumbs: 90;
$z-circle: 120;
$z-phone: 110;

// Bootstrap
$font-family-sans-serif: $font-regular;
$font-size-large: 18px;
$table-border-color: transparent;
$table-bg-accent: transparent;
$border-radius-base: 0;
$border-radius-large: 0;
$border-radius-small: 0;
$brand-primary: $dark-blue;

// Buttons
$btn-default-color: $green;
$btn-default-bg: $white;
$btn-default-border: $green;
$btn-success-color: $white;
$btn-success-bg: $green;
$btn-success-border: $green;
$btn-danger-color: $white;
$btn-danger-bg: $red;
$btn-danger-border: $red;

// Breadcrumbs
$breadcrumb-padding-vertical: 12px;
$breadcrumb-padding-horizontal: 15px;
$breadcrumb-bg: transparent;
$breadcrumb-color: $white;
$breadcrumb-active-color: $white;
$breadcrumb-separator: ">";

// Inputs
$input-bg: transparent;
$input-border: $white;
$input-border-radius: 0px;
$input-border-radius-large: 0px;
$input-border-radius-small: 0px;
$input-border-focus: $white;
$line-height-computed: 20px;

$pagination-active-bg: $blue;
$pagination-active-border: $blue;