.p-login-register {
    .p-text-white {
        color: $white;
    }

    .p-body {
        background: transparent;
        color: white;
    }

    .p-login-footer {
        background-color: white;
        padding: 25px 15px;
        width: 100%;
        position: fixed;
        bottom: 0;
        text-align: center;

        img {
            width: 200px;
            max-width: 100%;
        }
    }

    .p-register-form-container {
        position: relative;
    }

    .p-bottom-icons {
        padding: 30px;
        position: absolute;
        bottom: 0;

        a {
            color: $white;
        }
    }

    .p-login .p-bottom-icons {
        bottom: 130px;
    }

    .p-register-login {
        margin-bottom: 130px;

        .p-login-form-heading,
        .p-register-form-heading {
            margin-bottom: 15px;
        }

        .p-login-form,
        .p-register-form {
            padding: 100px 30px;
            max-width: 900px;
            margin: auto;

            .form-group {
                margin-bottom: 2px;
            }
        }

        .p-login-form {
            max-width: 500px;
        }

        .p-not-member {
            text-align: right;
            padding: 30px;

            a {
                color: $white;
            }
        }

        .p-bold-text {
            font-family: $font-bold !important;
        }

        .form-control {
            border-left: 0px;
            border-right: 0px;
            border-top: 0px;
            background-color: $white;
            height: 50px;
            box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075);
            color: $grey;

            .input-group {
                background-color: $white;
            }
        }

        .input-group-addon {
            padding: 6px 12px;
            font-size: 14px;
            font-weight: normal;
            line-height: 1;
            color: $light-grey;
            text-align: center;
            background-color: $white;
            border-top: 0px;
            border-bottom: 1px solid #fff;
            border-left: 0px;
            border-right: 0px;
            border-radius: 0px;

            .tooltip:hover:before {
                border: solid;
                border-color: #333 transparent;
                border-width: 6px 6px 0 6px;
                bottom: 20px;
                content: "";
                left: 50%;
                position: absolute;
                z-index: 99;
            }

            .tooltip:hover:after {
                background: #333;
                background: rgba(0, 0, 0, 0.8);
                border-radius: 5px;
                bottom: 26px;
                color: #fff;
                content: attr(title);
                left: 20%;
                padding: 5px 15px;
                position: absolute;
                z-index: 98;
                width: 220px;
            }

            .tooltip {
                display: inline;
                position: relative;
            }
        }
    }

    .p-btn {
        color: white;
        font-weight: bold;
        margin: 0;
    }

    .p-btn-link {
        border-color: transparent;
        padding-left: 0;
        padding-right: 0;
    }

    .p-btn-red {
        border: $white 2px solid;
        background-color: $red;
    }

    .p-btn-green {
        background-color: $green;
        color: $white;
        border: 2px solid $white;
    }
}

@media (max-height: 565px) {
    .p-login-footer,
    .p-bottom-icons {
        display: none;
    }
}

.p-login-register .p-client-register {
    .p-register-form-sub-heading {
        margin-bottom: 10px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        padding-bottom: 5px;
        font-weight: bold;
    }

    .p-register-form {
        padding-top: 0;
    }

    .p-register-form-container {
        width: 100%;
        max-width: 740px;
        margin: 0 auto;

        .form-group {
            margin-bottom: 20px;

            @media (min-width: $screen-md) {
                margin-bottom: 10px;
            }

            label {
                margin-bottom: 5px;

                @media (min-width: $screen-md) {
                    line-height: 50px;
                    margin: 0;
                }
            }

            .p-select-container > div {
                &:first-child {
                    padding-right: 8px;
                }
                &:last-child {
                    padding-left: 8px;
                }
            }

            img#p-loader {

            }
        }
    }

    .p-form-group {
        margin-bottom: 10px;
    }

    .p-form-notice {
        padding: 15px;
        background-color: $blue;
        color: $white;
        font-size: 15px;
        text-align: center;

        &.p-form-error {
            background-color: $red;
        }

        &.p-form-success {
            background-color: $green;
        }
    }

    #p-register-button {
        display: none;
    }

    @media (min-width: $screen-md) {
        .p-register-form {
            padding-top: 50px;
        }
    }
}
