.p-icon {
    display: inline-block;
    background-size: cover;
    background-repeat: no-repeat;
    width: 1em;
    height: 1em;
    color: white;
    background-size: 100% 100%;
}

.p-icon-lg {
    width: 2em;
    height: 2em;
}

.p-icon-page {
    .p-icon {
        margin: 20px;
        font-size: 3em;
        fill: $white;
    }

    .p-icon-wrapper {
        display: inline-block;
        padding: 20px;
        text-align: center;
        width: 16%;
    }
}

@each $name, $icon in $icons {
    .p-#{$name} {
        background-image: url("#{$iconBaseUrl}#{$icon}.svg");
    }
 
    @each $colorName, $colorHex in $iconColors {
        .p-btn:hover .p-#{$name}-#{$colorName} {
            background-image: url("#{$iconBaseUrl}#{$icon}-white.svg");
        }

        .p-#{$name}-#{$colorName} {
            background-image: url("#{$iconBaseUrl}#{$icon}-#{$colorName}.svg");
        }

        a:hover .p-#{$name}-#{$colorName},
        .p-btn[disabled] .p-#{$name}-#{$colorName} {
            background-image: url("#{$iconBaseUrl}#{$icon}-white.svg");
        }
    }
}

.p-swatch {
    display: inline-block;
    width: 1.25em;
    height: 1.25em;
    border: 1px solid white;
    border-radius: 1.25em;
    vertical-align: top;
}

.p-swatch-red {
    background-color: $red;
}

.p-swatch-green {
    background-color: $green;
}

.p-swatch-orange {
    background-color: $orange;
}

.p-swatch-yellow {
    background-color: $yellow;
}

.p-swatch-grey {
    background-color: $grey;
}

.p-swatch-purple {
    background-color: $purple;
}
