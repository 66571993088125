.p-colored-background {
    background: #00B6C6;
    background: linear-gradient(to right, #00B6C6, #00A2D1);

    /* Standard syntax */
    margin-bottom: 0px;
    color: white;
    background-size: cover;
    min-height: 100%;
    background-repeat: no-repeat;
}

.p-error-page {
    padding: 15px;
    margin: 0 auto;
    width: 100%;
    max-width: 720px;

    a {
        color: #A3E9ED;
        font-family: $font-bold;
    }
}

.p-cms {
    overflow-x: hidden;
    // background-image: url(/images/photo-pilot/img_clouds.jpg);
    background-repeat: no-repeat;
    // background-size: contain;
    background-size: cover;
    background-position: top;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .p-wrapper {
        flex: 1;
    }

    h2 {
        color: $blue;
        font-size: 1.3em;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    h3 {
        font-size: 1.4em;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    p {
        font-size: 1.2em;
        line-height: 1.5em;
    }

    table {
        width: 100% !important;
    }

    td > img {
        padding: 5px;
    }

    td {
        text-align: center;
    }
}

.cke_reset_all table {
    width: auto !important;
}

.p-centered {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

@media (max-width: 479px) {
    .p-error-page {
        padding-top: 40px;

        &.p-centered {
            margin-top: 10px;
        }
    }

    .p-centered {
        position: relative;
        margin: 120px 0 10px;
    }
}

@media (min-width: 480px) {
    .p-error-page {
        &.p-centered {
            height: 380px;
        }
    }
}

.p-download-page {
    min-height: 50vh;

    .p-download-page-frame {
        background: #00B6C6;
        background: linear-gradient(to right, #00B6C6, #00A2D1);
        border-radius: 15px;
        display: block;
        color: white;
        clear: both;
        padding: 25px;
    }

    .p-download-page-logo {
        width: 100px;
        margin-right: 15px;
    }
}
