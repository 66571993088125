.p-header {
    .p-header-logo {
        margin-left: 50px;
        margin-top: 50px;
        float: left;
        width: 200px;
    }

    @media (min-width: $screen-lg) {
        height: 200px;
    }
}

.p-navbar {
    padding: 20px;
    background-color: transparent;
    border-color: transparent;

    .navbar-nav a, 
    .navbar-nav a:hover, 
    .navbar-nav a:focus {
        background-color: transparent;
        color: $black;
        font-family: $font-barlow;
        font-size: 18px;
        font-weight: bold;
        text-align: center;

        &.pt-signup-link {
            border-radius: 20px;
            background-color: $blue;
            color: #FFFFFF;
            padding: 13px 40px;
        }
    }

    .navbar-nav a:hover {
        text-decoration: underline;
    }

    .footer {
        display: none;
    }
}

.p-picsos-phone {
    position: absolute;
    bottom: 0px;
    left: 16%;
}

.p-app-page {
    .p-intro {
        height: 335px;
        position: relative;
    }

    .p-btn {
        border-radius: 3em;
    }
}

.p-pirate {
    position: absolute;
    top: -12%;
    left: -6%;
    z-index: -10;
}

.p-intro {
    height: 535px;
    position: relative;
}

.p-appstore {
    display: inline-block;
    width: 30%;
}

.p-errors {
    color: $red;
}

.p-demo-appstore {
    margin-top: 20px;
    display: inline-block;
    width: 34%;
    padding: 3px;
}

.p-padding-md {
    padding: 20px;
}

.p-app {
    width: 25%;
    float: left;
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 43px;
    padding-bottom: 42px;
    background-color: #222327;
    position: relative;
}

.p-app:hover {
    background: #00B6C6;
    background: linear-gradient(to right, #00B6C6, #00A2D1);
}

.p-wrapper {
    position: relative;
    max-width: 900px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

#p-demo-section {
    height: 800px;

    .p-wrapper {
        .p-iphone {
            position: absolute;
            top: 120px;
            left: 103px;
            z-index: $z-phone;
            height: 562px;
            width: 266px;
            background: url("/images/iphone.png") no-repeat right;
            background-size: 100%;
        }

        .p-circle {
            position: absolute;
            top: 176px;
            left: -43px;
            z-index: 120;
            width: 200px;
            height: 200px;
            border-radius: 50%;
            overflow: hidden;
            box-shadow: 3px 4px 10px $dark-grey;
            border: 5px solid white;

            img {
                border-radius: 50%;
                overflow: hidden;
            }
        }

        .p-slider {
            text-align: center;
            margin-top: 124px;
            width: 200px;
            z-index: 900;
            height: 562px;
        }
    }
}

.p-bold-title {
    float: left;
    width: 40%;
    text-align: right;
    margin-right: 2%;
}

.slick-dots {
    bottom: -244px;
}

.p-app-header-content {
    margin-left: 50%;
    float: left;
    width: 45%;

    p {
        margin-bottom: 20px;
    }

    a {
        div {
            text-align: center;
            min-width: 100px;
        }
    }
}

.p-header-content {
    float: left;
    width: 55%;

    p {
        margin-bottom: 20px;
    }

    a {
        div {
            text-align: center;
            min-width: 100px;
        }
    }
}

.p-padding-lg {
    padding-left: 150px;
    padding-right: 140px;
    padding-top: 200px;
    padding-bottom: 200px;
}

.p-padding-sm {
    padding: 50px;
    padding-left: 200px;
    padding-right: 200px;
}

.p-light {
    background-color: $white;
}

.p-gradient {
    background: #00B6C6;
    background: linear-gradient(to right, #00B6C6, #00A2D1);
    color: $white;
}

.p-screens {
    margin-left: 7%;
    margin-top: 26%;
    width: 228px;
    height: 250px;
}

.p-app-synopsis {
    margin-top: 14%;
}

.p-circle-screen {
    width: 300px;
    height: 300px;
    overflow: hidden;
}

#p-comingsoonapp p {
    margin-top: 20px;
}

.p-icon-bar {
    background-color: #333;
}

.p-footer {
    background-color: white;
}

.p-wrapper-footer {
    border-top: 1px solid #D8D8D8;
    padding-top: 20px;
    padding-bottom: 10px;

    .p-footer-nav {
        div {
            list-style: none;
            float: right;
            margin-left: 10px;
            color: #00B6C5;
            font-family: $font-baloo;
            font-size: 20px;
        }
    }
}

.p-questions-text {
    h1 {
        margin-top: 0px;
    }
}

p {
    font-size: 1.3em;
    line-height: 1.8em;
}

.p-form .form-control,
.p-contact-form .form-control {
    display: block;
    width: 100%;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.428571429;
    color: $dark-grey;
    background-color: white;
    background-image: none;
    border: 1px solid $light-grey;
    border-radius: 0px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.p-group {
    background: url("/images/icon-bg/group.jpg");
    background-size: cover;
}

.p-imagine {
    background: url("/images/icon-bg/imagination.jpg");
    background-size: cover;
}

.p-arty {
    background: url("/images/icon-bg/arty.jpg");
    background-size: cover;
}

.p-restore {
    background: url("/images/icon-bg/restore.jpg");
    background-size: cover;
}

.p-caricature {
    background: url("/images/icon-bg/caricature.jpg");
    background-size: cover;
}

.p-logo {
    background: url("/images/icon-bg/logopics.jpg");
    background-size: cover;
}

.p-cutout {
    background: url("/images/icon-bg/cutoutpics.jpg");
    background-size: cover;
}

.p-profilebg {
    background: url("/images/icon-bg/profilepics.jpg");
    background-size: cover;
}

.p-layer {
    background-color: rgba(248, 247, 216, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.p-app-synopsis {
    h3 {
        font-size: 2.2em;
        font-family: $font-regular;
    }
}
