.animation-delay1 {
    animation-delay: 0.5s;
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    -ms-animation-delay: 0.5s;
    -o-animation-delay: 0.5s;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
    -moz-animation-timing-function: ease;
    -ms-animation-timing-function: ease;
    -o-animation-timing-function: ease;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
}

.animation-delay2 {
    animation-delay: 1.0s;
    -webkit-animation-delay: 1.0s;
    -moz-animation-delay: 1.0s;
    -ms-animation-delay: 1.0s;
    -o-animation-delay: 1.0s;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
    -moz-animation-timing-function: ease;
    -ms-animation-timing-function: ease;
    -o-animation-timing-function: ease;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
}

.animation-delay3 {
    animation-delay: 1.5s;
    -webkit-animation-delay: 1.5s;
    -moz-animation-delay: 1.5s;
    -ms-animation-delay: 1.5s;
    -o-animation-delay: 1.5s;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
    -moz-animation-timing-function: ease;
    -ms-animation-timing-function: ease;
    -o-animation-timing-function: ease;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
}

.animation-delay4 {
    animation-delay: 2.0s;
    -webkit-animation-delay: 2.0s;
    -moz-animation-delay: 2.0s;
    -ms-animation-delay: 2.0s;
    -o-animation-delay: 2.0s;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
    -moz-animation-timing-function: ease;
    -ms-animation-timing-function: ease;
    -o-animation-timing-function: ease;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
}

.animation-delay5 {
    animation-delay: 2.5s;
    -webkit-animation-delay: 2.5s;
    -moz-animation-delay: 2.5s;
    -ms-animation-delay: 2.5s;
    -o-animation-delay: 2.5s;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
    -moz-animation-timing-function: ease;
    -ms-animation-timing-function: ease;
    -o-animation-timing-function: ease;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
}

.animation-delay6 {
    animation-delay: 3.0s;
    -webkit-animation-delay: 3.0s;
    -moz-animation-delay: 3.0s;
    -ms-animation-delay: 3.0s;
    -o-animation-delay: 3.0s;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
    -moz-animation-timing-function: ease;
    -ms-animation-timing-function: ease;
    -o-animation-timing-function: ease;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
}

.animation-delay7 {
    animation-delay: 3.5s;
    -webkit-animation-delay: 3.5s;
    -moz-animation-delay: 3.5s;
    -ms-animation-delay: 3.5s;
    -o-animation-delay: 3.5s;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
    -moz-animation-timing-function: ease;
    -ms-animation-timing-function: ease;
    -o-animation-timing-function: ease;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
}

.animation-delay8 {
    animation-delay: 4.0s;
    -webkit-animation-delay: 4.0s;
    -moz-animation-delay: 4.0s;
    -ms-animation-delay: 4.0s;
    -o-animation-delay: 4.0s;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
    -moz-animation-timing-function: ease;
    -ms-animation-timing-function: ease;
    -o-animation-timing-function: ease;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
}

.animation-delay9 {
    animation-delay: 4.5s;
    -webkit-animation-delay: 4.5s;
    -moz-animation-delay: 4.5s;
    -ms-animation-delay: 4.5s;
    -o-animation-delay: 4.5s;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
    -moz-animation-timing-function: ease;
    -ms-animation-timing-function: ease;
    -o-animation-timing-function: ease;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
}

.animation-delay10 {
    animation-delay: 5.0s;
    -webkit-animation-delay: 5.0s;
    -moz-animation-delay: 5.0s;
    -ms-animation-delay: 5.0s;
    -o-animation-delay: 5.0s;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
    -moz-animation-timing-function: ease;
    -ms-animation-timing-function: ease;
    -o-animation-timing-function: ease;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
}
