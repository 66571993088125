.p-image-scale {
    width: 100%;
}

.p-small-image {
    max-width: 100px;
    max-height: 100px;
}

// Padding helpers
.p-no-padding {
    padding: 0 !important;
}

.p-padding-top {
    padding-top: $padding;
}

.p-padding-bottom {
    padding-bottom: $padding;
}

.p-padding-left {
    padding-left: $padding;
}

.p-padding-right {
    padding-right: $padding;
}

.p-padding-left-0 {
    padding-left: 0 !important;
}


// Margin helpers
.p-no-margin {
    margin: 0 !important;
}

.p-margin {
    margin: $margin;
}

.p-margin-top-0 {
    margin-top: 0;
}

.p-margin-top {
    margin-top: $margin;
}

.p-margin-top-3 {
    margin-top: $margin*3;
}

.p-margin-top-4 {
    margin-top: $margin*4;
}

.p-margin-left {
    margin-left: $margin;
}

.p-margin-left-0 {
    margin-left: 0 !important;
}

.p-margin-bottom {
    margin-bottom: $margin;
}

.p-margin-bottom-0 {
    margin-bottom: 0;
}

.p-margin-bottom-4 {
    margin-bottom: $margin*4;
}

.p-margin-right {
    margin-right: $margin;
}

.p-margin-right-0 {
    margin-right: 0 !important;
}

.p-margin-right-2 {
    margin-right: $margin*2;
}

.p-whitespace-pre {
    white-space: pre-line;
}

.p-inline-block {
    display: inline-block;
}

.p-vertical-scroll {
    max-height: 400px;
    overflow-y: auto;
}

.p-striped > div:nth-child(even) {
    background-color: #f5f5f5;
}

.p-vertical-align-top {
    vertical-align: top;
}

.p-visibility-hidden {
    visibility: hidden;
}

// Print helpers
.p-page-break {
    @media all {
        display: none;
    }

    @media print {
        display: block;
        page-break-before: always;
    }
}

// Display helpers
.p-display-block {
    display: block;
}

.p-display-inline {
    display: inline;
}

.p-display-inline-block {
    display: inline-block;
}

.p-tooltip {
    border-bottom: 1px dashed #aaa;
    display: inline-block;
    height: 1.4em;
}

// Image helpers
.p-img-responsive {
    @extend .img-responsive;
}

.p-img-full-width {
    width: 100%;
}
