.p-payment-container {
    max-width: 480px;
    margin: 0 auto;

    .p-payment-status {
        padding: 100px 15px;
    }

    @media (min-width: 768px) {
        .p-payment-status {
            padding: 210px 15px;
        }
    }
}

.p-form.p-payment-form {
    h3 {
        border-bottom: 1px solid $dark-blue;
        padding-bottom: 12px;
        margin-top: 50px;
    }

    #p-form-notice {
        display: none;
        margin: 40px 0 0;
        padding: 20px;
        font-size: 16px;
        text-align: center;
        color: $white;
        background-color: $green;
    }

    #p-form-notice.error {
        background-color: $red;
    }

    #p-loader {
        display: none;
        position: relative;
        top: 10px;
        right: 5px;
    }

    .form-fields {
        margin: 40px 0;
    }

    select.form-control:not(:first-child) {
        margin-bottom: 15px;
    }

    &.form-horizontal {
        .form-group {
            margin-left: 0;
            margin-right: 0;
        }

        .control-label {
            padding: 0;
            text-align: left;
            line-height: 32px;
        }
    }

    @media (min-width: 768px) {
        .form-control {
            width: 66.6666666667%;
        }

        select.form-control {
            width: 33.3333333333%;
        }

        select.form-control:not(:first-child) {
            margin-bottom: 0;
        }
    }
}
