/*==========  Non-Mobile First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
    .p-pirate {
        position: absolute;
        top: 0%;
        left: -6%;
        z-index: -10;
    }

    .p-intro-bold-title {
        margin-left: -45%;
        width: 50%;
    }

    .p-intro-header-content {
        margin-left: -21%;
    }

    .p-header {
        .p-header-logo {
            margin-left: 50px;
            margin-top: 40px;
            float: left;
        }

        @media (min-width: $screen-lg) {
            height: 200px;
        }
    }

    .p-wrapper {
        overflow: hidden;
    }

    .p-intro {
        height: 535px;
    }

    .p-intro-text {
        width: 60%;
        float: left;
    }

    .p-bold-title {
        text-align: left;
        width: 100%;
    }

    .p-header-content {
        float: left;
        width: 100%;

        h1 {
            margin-top: 0px;
        }
    }

    .p-app-header-content {
        float: left;
        width: 40%;
        margin-left: 50%;

        h1 {
            margin-top: 0px;
        }
    }

    .p-picsos-phone {
        position: absolute;
        bottom: -40px;
        left: 16%;
    }

    .p-app {
        width: 50%;
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: $dark-grey;
    }

    .p-padding-lg {
        padding: 36px;

        h1 {
            margin-bottom: 0px;
        }

        p {
            margin-top: 0px;
        }
    }

    p {
        font-size: 1em;
    }

    h1 {
        font-size: 28px;
    }

    .p-padding-sm {
        padding: 36px;
        padding-left: 36px;
        padding-right: 36px;
    }

    .p-appstore {
        display: block;
        margin-bottom: 10px;

        img {
            max-width: 135px;
        }
    }

    .p-app-synopsis {
        margin-left: 0px;
        margin-top: 5%;
        text-align: center;
    }

    .p-demo-appstore {
        display: inline-block;
        width: 37%;
    }

    #p-demo-section {
        height: 1130px;

        .p-wrapper {
            overflow: visible;

            .p-iphone {
                top: 60px;
                left: 250px;
                z-index: $z-phone;
                height: 562px;
                width: 266px;
                background: url("/images/iphone.png") no-repeat right;
                background-size: 100%;
            }

            .p-circle {
                top: 79px;
                left: 100px;
                z-index: $z-circle;
                border-radius: 50%;
                width: 200px;
                height: 200px;
            }

            .p-slider {
                text-align: center;
                margin-top: 124px;
                width: 200px;
                z-index: 900;
                height: 562px;
            }
        }
    }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 767px) {
    .p-pirate {
        width: 50%;
        position: relative;
        margin-left: 0%;
        top: 0%;
        left: 0%;
    }

    .p-wrapper-footer {
        display: none;
    }

    .p-header {
        height: auto;
    }

    .p-navbar {
        .footer {
            display: block;
        }

        background-color: transparent;
        border-color: transparent;

        .navbar-nav a, .navbar-nav a:hover, .navbar-nav a:focus {
            background-color: transparent;
            color: $dark-grey;
        }
    }

    .p-picsos-phone {
        position: absolute;
        bottom: -40px;
        left: 16%;
    }

    .p-app-header-content {
        float: left;
        width: 40%;
        margin-left: 50%;
    }

    #p-demo-section {
        height: 1130px;
    }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
    .p-pirate {
        width: 100%;
        position: relative;
        margin-left: -56%;
        top: 0%;
        left: 0%;
    }

    .p-app-header-content {
        float: left;
        width: 90%;
        margin-left: 5%;
    }

    .slick-dots {
        bottom: -150px;
    }

    .p-app-page {
        .p-intro {
            height: 540px;
        }
    }

    #p-demo-section {
        height: 1100px;

        .p-app-page .p-intro {
            height: 650px;
        }

        .p-wrapper {
            overflow: visible;

            .p-iphone {
                top: -15px;
                left: 90px;
                z-index: $z-phone;
                height: 562px;
                width: 200px;
                background: url("/images/iphone.png") no-repeat right;
                background-size: 100%;
            }

            .p-circle {
                top: 18px;
                left: 24px;
                z-index: $z-circle;
                border-radius: 50%;
                width: 150px;
                height: 150px;
                overflow: hidden;
                box-shadow: 6px 8px 10px $dark-grey;
                border: 5px solid white;

                img {}
            }

            .p-slider {
                text-align: center;
                margin-top: 124px;
                width: 200px;
                z-index: 900;
                height: 562px;
            }

            .p-pirate {
                position: relative;
                width: 63%;
                top: 0%;
                left: 0%;
                margin-left: -25%;
                float: left;
            }
        }
    }

    .p-screens {
        margin-left: 7%;
        margin-top: 61%;
        width: 172px;
        height: 250px;
    }
}

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {}
