.p-address {
    margin-top: 20px;

    a {
        color: $blue;
        font-size: 1.2em;
    }

    p {
        color: $dark-grey;
        font-size: 1.2em;
    }
}

.p-social-media {
    margin-top: 134px;
}

.p-contact-header {
    color: $blue;
    font-family: $font-bold !important;
}

.p-contact-form {
    color: $dark-grey;

    button {
        div {
            text-align: center;
            min-width: 100px;
        }
    }
}
